var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"620px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"size":"20"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c('v-col',{attrs:{"align":"center"}},[_c('h5',[_vm._v(_vm._s(_vm.formType === 'create' ? 'Create Employee Group' : 'Edit Employee Group'))])]),_c('v-col',{attrs:{"cols":"1"}})],1)],1),_c('v-divider'),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-card-text',{staticClass:"py-8 px-8"},[_c('v-text-field',{attrs:{"label":"Group name","outlined":"","rules":_vm.nameRules},model:{value:(_vm.currentEmployeeGroup.name),callback:function ($$v) {_vm.$set(_vm.currentEmployeeGroup, "name", $$v)},expression:"currentEmployeeGroup.name"}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.approverList,"item-text":function (ref) {
                  var name = ref.name;
                  var email = ref.email;

                  return name || email;
},"label":"Approved by","return-object":"","outlined":"","multiple":"","rules":_vm.approverRules},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-subheader',[_vm._v("Select by role")]),_vm._l((_vm.roleList),function(item){return _c('v-checkbox',{key:item.role,staticClass:"ml-4 mb-2",attrs:{"id":item.role,"label":item.role,"value":item,"multiple":"","ripple":"","hide-details":""},model:{value:(_vm.currentEmployeeGroup.setting.approver.list),callback:function ($$v) {_vm.$set(_vm.currentEmployeeGroup.setting.approver, "list", $$v)},expression:"currentEmployeeGroup.setting.approver.list"}})}),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.currentEmployeeGroup.setting.approver.list),callback:function ($$v) {_vm.$set(_vm.currentEmployeeGroup.setting.approver, "list", $$v)},expression:"currentEmployeeGroup.setting.approver.list"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.approvalTypeList,"label":"Approval type","outlined":"","rules":_vm.approvalTypeRules},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.approvalTypeText[item])+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.approvalTypeText[item])+" ")]}}]),model:{value:(_vm.currentEmployeeGroup.setting.approver.type),callback:function ($$v) {_vm.$set(_vm.currentEmployeeGroup.setting.approver, "type", $$v)},expression:"currentEmployeeGroup.setting.approver.type"}})],1)],1),_c('div',{staticClass:"mb-n2"},[_vm._v("Disbursement Type")]),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.currentEmployeeGroup.setting.disbursement_type),callback:function ($$v) {_vm.$set(_vm.currentEmployeeGroup.setting, "disbursement_type", $$v)},expression:"currentEmployeeGroup.setting.disbursement_type"}},[_c('v-radio',{attrs:{"label":"Manual","value":"manual"}})],1),_c('v-divider',{staticClass:"pb-6"}),_c('div',{staticClass:"mb-n2"},[_vm._v("Redeem Method")]),_c('v-radio-group',{attrs:{"row":""}},[_c('v-checkbox',{attrs:{"label":"Enable cashier","dense":""},model:{value:(_vm.currentEmployeeGroup.setting.cashier.status),callback:function ($$v) {_vm.$set(_vm.currentEmployeeGroup.setting.cashier, "status", $$v)},expression:"currentEmployeeGroup.setting.cashier.status"}})],1),_c('v-scroll-y-reverse-transition',[(_vm.currentEmployeeGroup.setting.cashier.status)?_c('div',{staticClass:"mt-2"},[_c('vue-phone-number-input',{attrs:{"id":"CreateEmployeeGroup","color":"#1F979E","valid-color":"#1F979E","error-color":"#FF4C51","default-country-code":"ID","clearable":"","size":"lg","dark":_vm.isDark,"error":_vm.phoneNumberInputData
                  ? !_vm.phoneNumberInputData.isValid || Boolean(_vm.errors.length)
                  : Boolean(_vm.errors.length)},on:{"update":function (data) { return (_vm.phoneNumberInputData = data); }},model:{value:(_vm.currentEmployeeGroup.setting.cashier.phone_number),callback:function ($$v) {_vm.$set(_vm.currentEmployeeGroup.setting.cashier, "phone_number", $$v)},expression:"currentEmployeeGroup.setting.cashier.phone_number"}}),_c('p',{class:_vm.errors.length ? 'mb-5' : 'mb-7',staticStyle:{"font-size":"0.8rem","color":"red"}},[_vm._v(" "+_vm._s(_vm.errors[0])+" ")]),_c('v-combobox',{attrs:{"label":"Bank Account","items":_vm.bankChannels,"item-text":"name","outlined":"","return-object":""},model:{value:(_vm.currentEmployeeGroup.setting.cashier.bank_account),callback:function ($$v) {_vm.$set(_vm.currentEmployeeGroup.setting.cashier, "bank_account", $$v)},expression:"currentEmployeeGroup.setting.cashier.bank_account"}}),_c('v-text-field',{attrs:{"label":"Account number","outlined":""},model:{value:(_vm.currentEmployeeGroup.setting.cashier.account_number),callback:function ($$v) {_vm.$set(_vm.currentEmployeeGroup.setting.cashier, "account_number", $$v)},expression:"currentEmployeeGroup.setting.cashier.account_number"}})],1):_vm._e()])],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loading}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }