var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h3',{staticClass:"mb-10"},[_vm._v(" Manage Salary ")]),_c('v-row',{staticClass:"mb-6"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){_vm.showCreateEmployeeGroupModal = true; _vm.formType = 'create'}}},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Create Employee Group ")],1),_c('create-employee-group-modal',{attrs:{"projects":_vm.projects,"form-type":_vm.formType},model:{value:(_vm.showCreateEmployeeGroupModal),callback:function ($$v) {_vm.showCreateEmployeeGroupModal=$$v},expression:"showCreateEmployeeGroupModal"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Search group name","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize select-project",attrs:{"outlined":"","block":_vm.$vuetify.breakpoint.xs}},'v-btn',attrs,false),on),[(_vm.project.logo)?_c('v-img',{staticClass:"logo-image",attrs:{"src":_vm.project.logo.uploadURL,"width":"48","height":"48","contain":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.project.name ? _vm.project.name : 'Select project')+" "),_c('v-icon',{attrs:{"right":"","size":"24"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1)]}}])},[_c('v-list',_vm._l((_vm.projects),function(item){return _c('v-list-item',{key:item.ID,attrs:{"link":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)},on:{"click":function($event){return _vm.$store.commit('manageSalary/setCurrentProject', item)}}})],1)}),1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredEmployeeGroups,"item-key":"id","search":_vm.search},scopedSlots:_vm._u([{key:"item.employee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.employeesID.length)+" ")]}},{key:"item.approver",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formattedApprover)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{staticClass:"text-capitalize me-4",style:(_vm.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.toEmployeeList(item.id)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? 'View Employee List' : '')+" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountGroup)+" ")]):_vm._e()],1),_c('v-btn',{staticClass:"action-btn me-4",style:(_vm.isDark
              ? { 'border-color': '#5E5669AD' }
              : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.toEditEmployeeGroup(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),_c('v-btn',{staticClass:"action-btn",style:(_vm.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)],1)],1):_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsHorizontalCircle))])],1)]}}],null,true)},[_c('v-list',{attrs:{"outlined":""}},[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccountGroup))])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.toEditEmployeeGroup(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)],1)],1)]}}])}),_c('confirm-delete-modal',{attrs:{"item-id":_vm.itemId,"loading":_vm.deleteLoading},on:{"handle-delete":_vm.deleteEmployeeGroup},model:{value:(_vm.showDeleteConfirmationModal),callback:function ($$v) {_vm.showDeleteConfirmationModal=$$v},expression:"showDeleteConfirmationModal"}},[_vm._v(" Are you sure you want to delete this employee group? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }