<template>
  <v-container>
    <!-- Header -->
    <h3 class="mb-10">
      Manage Salary
    </h3>

    <!-- Toolbar -->
    <v-row class="mb-6">
      <v-col
        cols="12"
        sm="6"
        md="auto"
      >
        <v-btn
          color="primary"
          class="text-capitalize"
          :block="$vuetify.breakpoint.smAndDown"
          @click="showCreateEmployeeGroupModal = true; formType = 'create'"
        >
          <v-icon
            left
            color="white"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Create Employee Group
        </v-btn>

        <create-employee-group-modal
          v-model="showCreateEmployeeGroupModal"
          :projects="projects"
          :form-type="formType"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search group name"
          single-line
          hide-details
          dense
          outlined
        >
        </v-text-field>
      </v-col>

      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-col
        cols="12"
        sm="6"
        md="auto"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              v-bind="attrs"
              class="text-capitalize select-project"
              :block="$vuetify.breakpoint.xs"
              v-on="on"
            >
              <v-img
                v-if="project.logo"
                :src="project.logo.uploadURL"
                width="48"
                height="48"
                contain
                class="logo-image"
              />
              {{ project.name ? project.name : 'Select project' }}
              <v-icon
                right
                size="24"
              >
                {{ icons.mdiChevronDown }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in projects"
              :key="item.ID"
              link
            >
              <v-list-item-title
                @click="$store.commit('manageSalary/setCurrentProject', item)"
                v-text="item.name"
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="filteredEmployeeGroups"
      item-key="id"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.employee="{ item }">
        {{ item.employeesID.length }}
      </template>

      <template v-slot:item.approver="{ item }">
        {{ item.formattedApprover }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col>
            <v-btn
              class="text-capitalize me-4"
              small
              outlined
              :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="toEmployeeList(item.id)"
            >
              {{ $vuetify.breakpoint.mdAndUp ? 'View Employee List' : '' }}
              <v-icon v-if="$vuetify.breakpoint.smAndDown">
                {{ icons.mdiAccountGroup }}
              </v-icon>
            </v-btn>
            <v-btn
              class="action-btn me-4"
              small
              outlined
              :style="isDark
                ? { 'border-color': '#5E5669AD' }
                : { 'border-color': 'lightgray' }"
              color="primary"
              @click="toEditEmployeeGroup(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>
            <v-btn
              class="action-btn"
              small
              outlined
              :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="confirmDeletion(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-menu
          v-else
          offset-y
          :close-on-content-click="true"
          :close-on-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
            </v-btn>
          </template>

          <v-list outlined>
            <v-btn
              icon
              color="primary"
            >
              <v-icon>{{ icons.mdiAccountGroup }}</v-icon>
            </v-btn>

            <v-btn
              icon
              color="primary"
              @click="toEditEmployeeGroup(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>

            <v-btn
              icon
              color="primary"
              @click="confirmDeletion(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <confirm-delete-modal
      v-model="showDeleteConfirmationModal"
      :item-id="itemId"
      :loading="deleteLoading"
      @handle-delete="deleteEmployeeGroup"
    >
      Are you sure you want to delete this employee group?
    </confirm-delete-modal>
  </v-container>
</template>

<script>
/* eslint-disable prefer-destructuring */
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import {
  mdiPlus,
  mdiMagnify,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiAccountGroup,
  mdiDotsHorizontalCircle,
  mdiChevronDown,
} from '@mdi/js'

import CreateEmployeeGroupModal from './CreateEmployeeGroup.vue'
import ConfirmDeleteModal from '../shared/ConfirmDelete.vue'

export default {
  name: 'ManageSalary',
  components: { CreateEmployeeGroupModal, ConfirmDeleteModal },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
        mdiAccountGroup,
        mdiDotsHorizontalCircle,
        mdiChevronDown,
      },
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Group name',
          align: 'start',
          value: 'name',
        },
        { text: 'Employee', value: 'employee' },
        { text: 'Approver', value: 'approver' },
        { text: 'Action', align: 'end', value: 'action' },
      ],
      showCreateEmployeeGroupModal: false,
      formType: '',
      showDeleteConfirmationModal: false,
      itemId: null,
      deleteLoading: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    projects() {
      return this.$store.getters['manageProject/getProjects']
    },
    employeeGroups() {
      return this.$store.getters['manageSalary/getEmployeeGroups']
    },

    // bisa dihapus yang dibawah kalau display approvernya udah bener
    // filteredEmployeeGroups() {
    //   return this.employeeGroups.filter(eg => eg.ID_project === this.project.ID)
    // },
    filteredEmployeeGroups() {
      const data = this.employeeGroups.filter(eg => eg.ID_project === this.project.ID)
      const dataWithAttachedFormattedApproverDisplay = data.map(employeeGroup => {
        const containerForByUser = {}
        const containerForByRole = {}
        employeeGroup.setting.approver.list.forEach(approver => {
          // by user
          if (approver?.email) {
            containerForByUser[approver?.email] = containerForByUser[approver?.email]++ || 0
          }

          // by role
          if (!approver?.email) {
            containerForByRole[approver?.role] = containerForByRole[approver?.role]++ || 0
          }
        })

        let formattedApprover = ''

        if (Object.keys(containerForByUser).length === 1) formattedApprover += Object.keys(containerForByUser)[0]
        if (Object.keys(containerForByRole).length === 1) formattedApprover += Object.keys(containerForByRole)[0]
        if (Object.keys(containerForByUser).length > 1)
          formattedApprover += `User: ${Object.keys(containerForByUser).length}`
        if (Object.keys(containerForByRole).length > 1)
          formattedApprover += `Role: ${Object.keys(containerForByRole).length}`
        if (Object.keys(containerForByUser).length === 1 && Object.keys(containerForByRole).length === 1)
          formattedApprover = `${Object.keys(containerForByUser)[0]} & ${Object.keys(containerForByRole)[0]}`

        return { ...employeeGroup, formattedApprover }
      })

      return dataWithAttachedFormattedApproverDisplay
    },
    project() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
  },
  async created() {
    this.$store.dispatch('manageSalary/getEmployeeGroups', { sub_id: this.user.sub_id })
    await this.$store.dispatch('manageProject/getProjects', { sub_id: this.user.sub_id })
    if (!Object.keys(this.project).length) {
      this.$store.commit('manageSalary/setCurrentProject', this.projects[0])
    }
  },
  methods: {
    async toEditEmployeeGroup(employeeGroupId) {
      await this.$store.dispatch('manageSalary/getEmployeeGroupById', {
        sub_id: this.user.sub_id,
        id: employeeGroupId,
      })
      this.formType = 'edit'
      this.showCreateEmployeeGroupModal = true
    },
    confirmDeletion(itemId) {
      this.itemId = itemId
      this.showDeleteConfirmationModal = true
    },
    async deleteEmployeeGroup(employeeGroupId) {
      this.deleteLoading = true
      await this.$store.dispatch('manageSalary/deleteEmployeeGroup', {
        sub_id: this.user.sub_id,
        id: employeeGroupId,
      })

      this.$store.dispatch('manageSalary/getEmployeeGroups', { sub_id: this.user.sub_id })
      this.deleteLoading = false
      this.showDeleteConfirmationModal = false
    },
    async toEmployeeList(employeeGroupId) {
      await this.$store.dispatch('manageSalary/getEmployeeGroupById', {
        sub_id: this.user.sub_id,
        id: employeeGroupId,
      })
      this.$router.push(`/manage-salary/employee-group/${employeeGroupId}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}

.v-btn.select-project {
  padding: 0;
  border: none;
  font-size: 18px;
  font-weight: bold;
  height: 48px !important;

  .logo-image {
    border: 1px solid lightgrey;
    border-radius: 3px;
    margin-right: 1rem;
  }

  span.v-ripple__container {
    opacity: 0 !important;
  }
}

.select-project:before {
  opacity: 0 !important;
}
</style>
